import React from "react";
import SubPageTitle from '../components/sub-page-title/subPageTitle';
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionDescription from "../components/section-description/section-description";
import '../css/polityka-prywatnosci.scss';
import Button from "../components/button/button";


export default class PolitykaPrywatnosci extends React.Component {
  redirectToOffer(){
    console.log('redirect to offer');
  }
  render(){
    return <Layout>
      <SEO 
        title="Dziękujemy za wypełnienie briefu!"
        description="Dziękujemy za wypełnienie briefu" 
        url="https://jointoweb.pl/dziekujemy-za-wypelnienie-briefu"
        lang="pl" 
      />
      <div className="section section--brief">
        <div className="section-brief--title section-brief--title--relative">
          <SubPageTitle text={<span>Dziękujemy <strong>za wypełnienie briefu</strong></span>}/>
          <div className="section-text--description">
            <SectionDescription content="Wysłaliśmy do Ciebie wiadomość z linkiem, dzięki któremu  możesz potwierdzić adres e-mail. Po przeanalizowaniu danych, wkrótce przedstawimy Ci gotowy plan."/>
          </div>
          <Button text="Zobacz usługi" url="/" />
        </div>
      </div>
    </Layout>
  }
}